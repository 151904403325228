import { InjectionToken, Provider, Type } from '@angular/core';

export const DIALOG_POLYFILL_SERVICE_TOKEN = new InjectionToken<DialogPolyfillService>('DialogPolyfillService');

export function provideDialogPolyfillService(service: Type<DialogPolyfillService>): Provider {
  return {
    provide: DIALOG_POLYFILL_SERVICE_TOKEN,
    useClass: service,
  }
}

export interface DialogPolyfillService {

  registerDialog(element: HTMLDialogElement): void;

}
